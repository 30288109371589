.videoListComponent {
  width   : 100%;
  height  : 100%;
  overflow: hidden;
  position: relative;

  .num {
    border  : 1px solid white;
    position: absolute;
  }

  &.layout-1 {
    .num-1 {
      top   : 0;
      bottom: 0;
      left  : 0;
      right : 0;
    }
  }

  &.layout-2 {
    .num {
      top   : 0;
      bottom: 0;
    }

    .num-1 {
      left : 0;
      right: 50%;
    }

    .num-2 {
      left : 50%;
      right: 0;
    }
  }

  &.layout-4 {
    .num-1 {
      top   : 0;
      bottom: 50%;
      left  : 0;
      right : 50%;
    }

    .num-2 {
      top   : 0;
      bottom: 50%;
      left  : 50%;
      right : 0;
    }

    .num-3 {
      top   : 50%;
      bottom: 0;
      left  : 0;
      right : 50%;
    }

    .num-4 {
      top   : 50%;
      bottom: 0;
      left  : 50%;
      right : 0;
    }
  }

  &.layout-6 {
    .num-1 {
      top   : 0;
      left  : 0;
      right : 33.333333%;
      bottom: 33.333333%;
    }

    .num-2 {
      top   : 0;
      left  : 66.666666%;
      right : 0;
      bottom: 66.666666%;
    }

    .num-3 {
      top   : 33.333333%;
      left  : 66.666666%;
      right : 0;
      bottom: 33.333333%;
    }

    .num-4 {
      top   : 66.666666%;
      left  : 0;
      right : 66.666666%;
      bottom: 0;
    }

    .num-5 {
      top   : 66.666666%;
      bottom: 0;
      left  : 33.333333%;
      right : 33.333333%;
    }

    .num-6 {
      top   : 66.666666%;
      bottom: 0;
      left  : 66.666666%;
      right : 0;
    }
  }

  &.layout-8 {
    .num-1 {
      top   : 0;
      bottom: 25%;
      left  : 0;
      right : 25%;
    }

    .num-2 {
      top   : 0;
      bottom: 75%;
      left  : 75%;
      right : 0;
    }

    .num-3 {
      top   : 25%;
      bottom: 50%;
      left  : 75%;
      right : 0;
    }

    .num-4 {
      top   : 50%;
      bottom: 25%;
      left  : 75%;
      right : 0;
    }

    .num-5 {
      top   : 75%;
      bottom: 0;
      left  : 0;
      right : 75%;
    }

    .num-6 {
      top   : 75%;
      bottom: 0;
      left  : 25%;
      right : 50%;
    }

    .num-7 {
      top   : 75%;
      bottom: 0;
      left  : 50%;
      right : 25%;
    }

    .num-8 {
      top   : 75%;
      bottom: 0;
      left  : 75%;
      right : 0;
    }
  }

  &.layout-9 {
    .num-1 {
      top   : 0;
      bottom: 66.666666%;
      left  : 0;
      right : 66.666666%;
    }

    .num-2 {
      top   : 0;
      bottom: 66.666666%;
      left  : 33.333333%;
      right : 33.333333%;
    }

    .num-3 {
      top   : 0;
      bottom: 66.666666%;
      left  : 66.666666%;
      right : 0;
    }

    .num-4 {
      top   : 33.333333%;
      bottom: 33.333333%;
      left  : 0;
      right : 66.666666%;
    }

    .num-5 {
      top   : 33.333333%;
      bottom: 33.333333%;
      left  : 33.333333%;
      right : 33.333333%;
    }

    .num-6 {
      top   : 33.333333%;
      bottom: 33.333333%;
      left  : 66.666666%;
      right : 0;
    }

    .num-7 {
      top   : 66.666666%;
      bottom: 0;
      left  : 0;
      right : 66.666666%;
    }

    .num-8 {
      top   : 66.666666%;
      bottom: 0;
      left  : 33.333333%;
      right : 33.333333%;
    }

    .num-9 {
      top   : 66.666666%;
      bottom: 0;
      left  : 66.666666%;
      right : 0;
    }
  }

  &.layout-12 {
    .num-1 {
      top   : 0;
      bottom: 66.666666%;
      left  : 0;
      right : 75%;
    }

    .num-2 {
      top   : 0;
      bottom: 66.666666%;
      left  : 25%;
      right : 50%;
    }

    .num-3 {
      top   : 0;
      bottom: 66.666666%;
      left  : 50%;
      right : 25%;
    }

    .num-4 {
      top         : 0;
      bottom      : 66.666666%;
      left        : 75%;
      right       : 0;
      border-top  : 1px solid #e8eaec;
      border-left : 1px solid #e8eaec;
      border-right: 1px solid #e8eaec;
    }

    .num-5 {
      top   : 33.333333%;
      bottom: 33.333333%;
      left  : 0;
      right : 75%;
    }

    .num-6 {
      top   : 33.333333%;
      bottom: 33.333333%;
      left  : 25%;
      right : 50%;
    }

    .num-7 {
      top   : 33.333333%;
      bottom: 33.333333%;
      left  : 50%;
      right : 25%;
    }

    .num-8 {
      top         : 33.333333%;
      bottom      : 33.333333%;
      left        : 75%;
      right       : 0;
      border-top  : 1px solid #e8eaec;
      border-left : 1px solid #e8eaec;
      border-right: 1px solid #e8eaec;
    }

    .num-9 {
      top   : 66.666666%;
      bottom: 0;
      left  : 0;
      right : 75%;
    }

    .num-10 {
      top   : 66.666666%;
      bottom: 0;
      left  : 25%;
      right : 50%;
    }

    .num-11 {
      top   : 66.666666%;
      bottom: 0;
      left  : 50%;
      right : 25%;
    }

    .num-12 {
      top          : 66.666666%;
      bottom       : 0;
      left         : 75%;
      right        : 0;
      border-top   : 1px solid #e8eaec;
      border-left  : 1px solid #e8eaec;
      border-right : 1px solid #e8eaec;
      border-bottom: 1px solid #e8eaec;
    }
  }

  &.layout-13 {
    .num-1 {
      top   : 25%;
      bottom: 25%;
      left  : 25%;
      right : 25%;
    }

    .num-2 {
      top   : 0;
      bottom: 75%;
      left  : 0;
      right : 75%;
    }

    .num-3 {
      top   : 0;
      bottom: 75%;
      left  : 25%;
      right : 50%;
    }

    .num-4 {
      top   : 0;
      bottom: 75%;
      left  : 50%;
      right : 25%;
    }

    .num-5 {
      top         : 0;
      bottom      : 75%;
      left        : 75%;
      right       : 0;
      border-top  : 1px solid #e8eaec;
      border-left : 1px solid #e8eaec;
      border-right: 1px solid #e8eaec;
    }

    .num-6 {
      top   : 25%;
      bottom: 50%;
      left  : 0;
      right : 75%;
    }

    .num-7 {
      top         : 25%;
      bottom      : 50%;
      left        : 75%;
      right       : 0;
      border-top  : 1px solid #e8eaec;
      border-left : 1px solid #e8eaec;
      border-right: 1px solid #e8eaec;
    }

    .num-8 {
      top   : 50%;
      bottom: 25%;
      left  : 0;
      right : 75%;
    }

    .num-9 {
      top         : 50%;
      bottom      : 25%;
      left        : 75%;
      right       : 0;
      border-top  : 1px solid #e8eaec;
      border-left : 1px solid #e8eaec;
      border-right: 1px solid #e8eaec;
    }

    .num-10 {
      top   : 75%;
      bottom: 0;
      left  : 0;
      right : 75%;
    }

    .num-11 {
      top   : 75%;
      bottom: 0;
      left  : 25%;
      right : 50%;
    }

    .num-12 {
      top   : 75%;
      bottom: 0;
      left  : 50%;
      right : 25%;
    }

    .num-13 {
      top          : 75%;
      bottom       : 0;
      left         : 75%;
      right        : 0;
      border-top   : 1px solid #e8eaec;
      border-left  : 1px solid #e8eaec;
      border-right : 1px solid #e8eaec;
      border-bottom: 1px solid #e8eaec;
    }
  }


  &.layout-16{

    .num-1 {
     top   : 0;
     bottom: 75%;
     left  : 0;
     right : 75%;
   }
 
    .num-2 {
     top   : 0;
     bottom: 75%;
     left  : 25%;
     right : 50%;
   }
 
    .num-3 {
     top   : 0;
     bottom: 75%;
     left  : 50%;
     right : 25%;
   }
 
    .num-4 {
     top         : 0;
     bottom      : 75%;
     left        : 75%;
     right       : 0;
     border-top  : 1px solid #e8eaec;
     border-left : 1px solid #e8eaec;
     border-right: 1px solid #e8eaec;
   }
 
    .num-5 {
     top   : 25%;
     bottom: 50%;
     left  : 0;
     right : 75%;
   }
 
    .num-6 {
     top   : 25%;
     bottom: 50%;
     left  : 25%;
     right : 50%;
   }
 
    .num-7 {
     top   : 25%;
     bottom: 50%;
     left  : 50%;
     right : 25%;
   }
 
    .num-8 {
     top         : 25%;
     bottom      : 50%;
     left        : 75%;
     right       : 0;
     border-top  : 1px solid #e8eaec;
     border-left : 1px solid #e8eaec;
     border-right: 1px solid #e8eaec;
   }
 
    .num-9 {
     top   : 50%;
     bottom: 25%;
     left  : 0;
     right : 75%;
   }
 
    .num-10 {
     top   : 50%;
     bottom: 25%;
     left  : 25%;
     right : 50%;
   }
 
    .num-11 {
     top   : 50%;
     bottom: 25%;
     left  : 50%;
     right : 25%;
   }
 
    .num-12 {
     top         : 50%;
     bottom      : 25%;
     left        : 75%;
     right       : 0;
     border-top  : 1px solid #e8eaec;
     border-left : 1px solid #e8eaec;
     border-right: 1px solid #e8eaec;
   }
 
    .num-13 {
     top   : 75%;
     bottom: 0;
     left  : 0;
     right : 75%;
   }
 
    .num-14 {
     top   : 75%;
     bottom: 0;
     left  : 25%;
     right : 50%;
   }
 
    .num-15 {
     top   : 75%;
     bottom: 0;
     left  : 50%;
     right : 25%;
   }
 
    .num-16 {
     top          : 75%;
     bottom       : 0;
     left         : 75%;
     right        : 0;
     border-top   : 1px solid #e8eaec;
     border-left  : 1px solid #e8eaec;
     border-right : 1px solid #e8eaec;
     border-bottom: 1px solid #e8eaec;
   }
  } 

  &.layout-17{
    .num-2 {
      top   : 0;
      bottom: 80%;
      left  : 0;
      right : 80%;
    }
  
    .num-3 {
      top   : 0;
      bottom: 80%;
      left  : 20%;
      right : 60%;
    }
  
    .num-4 {
      top   : 0;
      bottom: 80%;
      left  : 40%;
      right : 40%;
    }
  
    .num-5 {
      top   : 0;
      bottom: 80%;
      left  : 60%;
      right : 20%;
    }
  
    .num-6 {
      top         : 0;
      bottom      : 80%;
      left        : 80%;
      right       : 0;
      border-top  : 1px solid #e8eaec;
      border-left : 1px solid #e8eaec;
      border-right: 1px solid #e8eaec;
    }
  
    .num-7 {
      top   : 20%;
      bottom: 60%;
      left  : 0;
      right : 80%;
    }
  
    .num-1 {
      top   : 20%;
      bottom: 20%;
      left  : 20%;
      right : 20%;
    }
  
    .num-8 {
      top         : 20%;
      bottom      : 60%;
      left        : 80%;
      right       : 0;
      border-top  : 1px solid #e8eaec;
      border-left : 1px solid #e8eaec;
      border-right: 1px solid #e8eaec;
    }
  
    .num-9 {
      top   : 40%;
      bottom: 40%;
      left  : 0;
      right : 80%;
    }
  
    .num-10 {
      top         : 40%;
      bottom      : 40%;
      left        : 80%;
      right       : 0;
      border-top  : 1px solid #e8eaec;
      border-left : 1px solid #e8eaec;
      border-right: 1px solid #e8eaec;
    }
  
    .num-11 {
      top   : 60%;
      bottom: 20%;
      left  : 0;
      right : 80%;
    }
  
    .num-12 {
      top         : 60%;
      bottom      : 20%;
      left        : 80%;
      right       : 0;
      border-top  : 1px solid #e8eaec;
      border-left : 1px solid #e8eaec;
      border-right: 1px solid #e8eaec;
    }
  
    .num-13 {
      top          : 80%;
      bottom       : 0%;
      left         : 0;
      right        : 80%;
      border-top   : 1px solid #e8eaec;
      border-left  : 1px solid #e8eaec;
      border-bottom: 1px solid #e8eaec;
    }
  
    .num-14 {
      top          : 80%;
      bottom       : 0%;
      left         : 20%;
      right        : 60%;
      border-top   : 1px solid #e8eaec;
      border-left  : 1px solid #e8eaec;
      border-bottom: 1px solid #e8eaec;
    }
  
    .num-15 {
      top          : 80%;
      bottom       : 0%;
      left         : 40%;
      right        : 40%;
      border-top   : 1px solid #e8eaec;
      border-left  : 1px solid #e8eaec;
      border-bottom: 1px solid #e8eaec;
    }
  
    .num-16 {
      top          : 80%;
      bottom       : 0%;
      left         : 60%;
      right        : 20%;
      border-top   : 1px solid #e8eaec;
      border-left  : 1px solid #e8eaec;
      border-bottom: 1px solid #e8eaec;
    }
  
    .num-17 {
      top          : 80%;
      bottom       : 0%;
      left         : 80%;
      right        : 0;
      border-top   : 1px solid #e8eaec;
      border-left  : 1px solid #e8eaec;
      border-right : 1px solid #e8eaec;
      border-bottom: 1px solid #e8eaec;
    }

  }


  &.layout-25{
    .num-1 {
      top   : 0;
      bottom: 80%;
      left  : 0;
      right : 80%;
    }
  
    .num-2 {
      top   : 0;
      bottom: 80%;
      left  : 20%;
      right : 60%;
    }
  
    .num-3 {
      top   : 0;
      bottom: 80%;
      left  : 40%;
      right : 40%;
    }
  
    .num-4 {
      top   : 0;
      bottom: 80%;
      left  : 60%;
      right : 20%;
    }
  
    .num-5 {
      top         : 0;
      bottom      : 80%;
      left        : 80%;
      right       : 0;
      border-top  : 1px solid #e8eaec;
      border-left : 1px solid #e8eaec;
      border-right: 1px solid #e8eaec;
    }
  
    .num-6 {
      top   : 20%;
      bottom: 60%;
      left  : 0;
      right : 80%;
    }
  
    .num-7 {
      top   : 20%;
      bottom: 60%;
      left  : 20%;
      right : 60%;
    }
  
    .num-8 {
      top   : 20%;
      bottom: 60%;
      left  : 40%;
      right : 40%;
    }
  
    .num-9 {
      top   : 20%;
      bottom: 60%;
      left  : 60%;
      right : 20%;
    }
  
    .num-10 {
      top         : 20%;
      bottom      : 60%;
      left        : 80%;
      right       : 0;
      border-top  : 1px solid #e8eaec;
      border-left : 1px solid #e8eaec;
      border-right: 1px solid #e8eaec;
    }
  
    .num-11 {
      top   : 40%;
      bottom: 40%;
      left  : 0;
      right : 80%;
    }
  
    .num-12 {
      top   : 40%;
      bottom: 40%;
      left  : 20%;
      right : 60%;
    }
  
    .num-13 {
      top   : 40%;
      bottom: 40%;
      left  : 40%;
      right : 40%;
    }
  
    .num-14 {
      top   : 40%;
      bottom: 40%;
      left  : 60%;
      right : 20%;
    }
  
    .num-15 {
      top         : 40%;
      bottom      : 40%;
      left        : 80%;
      right       : 0;
      border-top  : 1px solid #e8eaec;
      border-left : 1px solid #e8eaec;
      border-right: 1px solid #e8eaec;
    }
  
    .num-16 {
      top   : 60%;
      bottom: 20%;
      left  : 0;
      right : 80%;
    }
  
    .num-17 {
      top   : 60%;
      bottom: 20%;
      left  : 20%;
      right : 60%;
    }
  
    .num-18 {
      top   : 60%;
      bottom: 20%;
      left  : 40%;
      right : 40%;
    }
  
    .num-19 {
      top   : 60%;
      bottom: 20%;
      left  : 60%;
      right : 20%;
    }
  
    .num-20 {
      top         : 60%;
      bottom      : 20%;
      left        : 80%;
      right       : 0;
      border-top  : 1px solid #e8eaec;
      border-left : 1px solid #e8eaec;
      border-right: 1px solid #e8eaec;
    }
  
    .num-21 {
      top          : 80%;
      bottom       : 0%;
      left         : 0;
      right        : 80%;
      border-top   : 1px solid #e8eaec;
      border-left  : 1px solid #e8eaec;
      border-bottom: 1px solid #e8eaec;
    }
  
    .num-22 {
      top          : 80%;
      bottom       : 0%;
      left         : 20%;
      right        : 60%;
      border-top   : 1px solid #e8eaec;
      border-left  : 1px solid #e8eaec;
      border-bottom: 1px solid #e8eaec;
    }
  
    .num-23 {
      top          : 80%;
      bottom       : 0%;
      left         : 40%;
      right        : 40%;
      border-top   : 1px solid #e8eaec;
      border-left  : 1px solid #e8eaec;
      border-bottom: 1px solid #e8eaec;
    }
  
    .num-24 {
      top          : 80%;
      bottom       : 0%;
      left         : 60%;
      right        : 20%;
      border-top   : 1px solid #e8eaec;
      border-left  : 1px solid #e8eaec;
      border-bottom: 1px solid #e8eaec;
    }
  
    .num-25 {
      top          : 80%;
      bottom       : 0%;
      left         : 80%;
      right        : 0;
      border-top   : 1px solid #e8eaec;
      border-left  : 1px solid #e8eaec;
      border-right : 1px solid #e8eaec;
      border-bottom: 1px solid #e8eaec;
    }

  }

}