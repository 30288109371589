.devConfigForm {
  padding-top: 10px;
  flex: 1;
  min-height: 1px;
  display: flex;

  >.parent {
    border: 1px solid #E9EEF7;
    height: 100%;
    overflow: auto;

    .typeItem {
      padding: 6px 14px;
      border-bottom: 1px solid #E9EEF7;
      cursor: pointer;

      &.active {
        color: white;
      }
    }
  }

  >form {
    height: 100%;
    overflow: auto;
    flex: 1;
    min-width: 1px;
    border: 1px solid #E9EEF7;
    border-left: none;

    .formItem {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .xxskr {
        width: 360px;
        display: flex;
        align-items: center;

        .skr {
          flex: 1;
          min-width: 1px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .ant-form-item {
        flex: 1;
        min-width: 1px;
        margin: 0 0 0 20px;
      }

      .ant-checkbox-wrapper {
        margin: 5px 10px;
      }

    }


  }

}

.configModal {
  display: flex;
  flex: 1 1;
  flex-direction: column;

  .tips {
    margin-left: 20px;
    color: #9f9f9f;
    font-size: 12px;

    .anticon {
      padding-right: 3px;
    }
  }
  .content {
    display: flex;
    flex: 1 1;
    flex-direction: column;


    .title {
      width: 120px;
    }

    .top {
      display: flex;
      height: 80px;
      align-items: center;
      line-height: 80px;
      border-bottom: 1px dashed lightgray;

      .ant-form-item {
        margin-bottom: 0px;
      }
    }

    .mid {
      display: flex;
      height: 80px;
      line-height: 80px;

      .ant-slider-disabled {
        margin-top: 32px;
      }
    }

    .bottom {
      display: flex;
      height: 80px;
      line-height: 80px;
      align-items: center;
    }
  }
}

.templateManageInputDialog {
  .top {
    .title {
      font-size: 15px;
      font-weight: bold;
      margin-right: 20px;
    }
  }

  .ant-checkbox-group {
    display: flex;
    flex: 1 1;

    .ant-row {
      flex: 1 1;

      .ant-col.ant-col-12 {
        margin-top: 30px;
      }
    }
  }
}

.zltsModal{
  
  .footer {
    position: relative; 
    display: flex;  
    justify-content: flex-end;
    padding: 4px 0;
    .tips {
      // position: absolute;
      // left: 50%;
      color: #9f9f9f;
      font-size: 12px;
      margin: 6px;
  
      .anticon {
        padding-right: 3px;
      }
    }
    
    button{
      // margin-left: calc(50% - 32px) !important;
      margin-right: 0;
    }
  }
}