$blue: #1890ff;

.productTestDetailPage {
  width: 100%;
  height: 100%;
  padding: 10px;
  overflow: auto;

  >div {
    min-width: 1280px;
    height: 100%;

    >.ant-tabs {
      height: 100%;
      display: flex;
      flex-direction: column;

      .ant-tabs-content {
        flex: 1;
        min-height: 1px;
      }
    }
  }

  .afterCom,
  .boforeCom {
    height: 100%;
    display: flex;
    flex-direction: column;

    &.camera {
      width: 50%;
    }

    >.top {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      border-bottom: 1px solid lightgrey;
      padding-bottom: 16px;

      >.title {
        font-size: 20px;
        font-weight: bold;
      }

      >.time {
        margin: 0 2vw 0 1vw;
      }
    }

    >.main {
      display: flex;
      flex: 1;
      min-height: 1px;
      overflow: auto;

      >.col {
        flex: 1;
        min-width: 1px;

        // &:nth-child(3){
        //   flex: 1;
        // }

        &:last-child {
          flex: 1.2;

          .ant-input-group-addon {
            width: 190px !important;
          }
        }

        .block {
          padding: 0 10px;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          >.title {
            color: $blue;
            font-size: 20px;
            // font-weight: bold;
          }

          .list {
            .ant-input-group-wrapper {
              margin: 4px 0;

              .ant-input-group-addon {
                background-color: #eee;
                width: 120px;
              }

              .ant-input[value='N/A'] {
                color: red;
              }
            }
          }
        }
      }
    }

  }
}