.App {
  // text-align: center;
  width: 100vw;
  height    : 100vh;
  // background: #f4f6f8;
}

.App-logo {
  height        : 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height      : 100vh;
  display         : flex;
  flex-direction  : column;
  align-items     : center;
  justify-content : center;
  font-size       : calc(10px + 2vmin);
  color           : white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*滚动条整体样式*/
::-webkit-scrollbar {
  width : 8px;
  height: 8px;
  cursor: pointer;
}

/*滚动条滑块*/
::-webkit-scrollbar-thumb {
  background   : #BFC8DC;
  border-radius: 3px;
  &:hover{
    background: #b9c0ce;
  }
}

/*滚动条轨道*/
// ::-webkit-scrollbar-track {
//   // background: #f5f5f5;
// }


.ant-modal-root .antdTableAndPagination,
#root .antdTableAndPagination {

  .ant-table-header{
    // border: 1px solid
    background-color: #f4f6f8 !important;
  }

  .ant-spin.ant-spin-spinning{
    max-height: initial ;
  }

  table {

    .ant-table-thead>tr>th{
      background-color: #f4f6f8 !important;
    }

    .ant-table-thead>tr>th,
    .ant-table-tbody>tr>td {
      padding: 8px 6px;
      text-align: center;
    }
  }

  .ant-pagination {
    margin: 8px;
    margin-bottom: 0;
  }
}

.videoChannelCom{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .ch{
    position: relative;
    display: flex;
    color: #6070FB;
    margin:0 2px;
    justify-content: center;
    align-items: center;
    // font-weight: bold;
    &.disabled{
      // background: gray;
      color: #a7a7a7;
    }
    .myIconComponent{
      font-size: 20px;
      margin-right: 5px;
    }
    .no{
      display: block;
      position: absolute;
      text-align: center;
      width: 14px;
      left: -7px;
      top: -12px;
    }

  }
}

.mapStorageCom{
  color: #6070FB;
  display: inline-block;
  position: relative;
  top: 2px; 
  .myIconComponent{
    font-size: 18px;
    margin-right: 5px;
    &.disabled{
      color: #a7a7a7;
    }
  }
}